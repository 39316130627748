import { Box } from "@mui/material";
import { SoilLeftArrow, SoilRightArrow } from "../../svg";
import "./SoilSelector.css";

function SoilSelector({ soilType, setSoilType, index, max }) {
  const handleLeftArrow = () => {
    let newIndex = index - 1 < 0 ? max : index - 1;
    setSoilType(newIndex);
  };

  const handleRightArrow = () => {
    let newIndex = index + 1 > max ? 0 : index + 1;
    setSoilType(newIndex);
  };

  return (
    <Box sx={{ gridArea: "selector" }} className="soil-selector">
      <SoilLeftArrow className="soil-arrow" onClick={handleLeftArrow} />
      {soilType}
      <SoilRightArrow className="soil-arrow" onClick={handleRightArrow} />
    </Box>
  );
}

export default SoilSelector;
