import "./App.css";
import Calculator from "./components/Calculator/Calculator";
import NavBar from "./components/NavBar/NavBar";
import Menu from "./components/Menu/Menu";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { menuNames } from "./constants";
import useOrientation from "./utils/useOrientation";
import { CSSTransition } from "react-transition-group";
library.add(fas);

function App() {
  const [currMenu, setCurrMenu] = useState("Main");
  const orientation = useOrientation();
  const useMenu = orientation === "portrait" && currMenu !== menuNames.MAIN;

  return (
    <div className="App">
      <NavBar currMenu={currMenu} setCurrMenu={setCurrMenu} />
      <div className="content">
        {useMenu ? (
          <Menu currMenu={currMenu} setCurrMenu={setCurrMenu} />
        ) : (
          <Calculator />
        )}
      </div>
    </div>
  );
}

export default App;
