import "./References.css";
import { config } from "../../config";
import { RightCaretBlack } from "../svg";
import { useState } from "react";
import ReferenceItem from "../ReferenceItem/ReferenceItem";
import useOrientation from "../../utils/useOrientation";

const References = () => {
  const refList = config.reference;
  const [refItem, setRefItem] = useState(null);
  const [showItem, setShowItem] = useState(false);
  const orientation = useOrientation();
  const isLandscape = orientation === "landscape";

  return (
    <div
      className="References"
      style={{
        backgroundColor: refItem ? "var(--bg)" : "white",
        borderRadius: isLandscape ? "10px" : "",
      }}
    >
      {!showItem ? (
        refList.map((ele, i) => {
          return (
            <div
              onClick={() => {
                const refItem = config.reference[i].sections;
                setRefItem(refItem);
                setShowItem(true);
              }}
              className="ref-item"
              key={ele.title}
            >
              <div className="ref-item-title">{ele.title}</div>
              <div className="ref-item-icon">
                <RightCaretBlack />
              </div>
            </div>
          );
        })
      ) : (
        <ReferenceItem refItem={refItem} setShowItem={setShowItem} />
      )}
    </div>
  );
};

export default References;
