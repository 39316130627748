import { config } from "../../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Links.css";

function Links() {

  const linksData = config.links;

  return <>
  <div className="Link">
      {linksData && linksData.map((e,i) => {
        return <a key={i} className="link-item" style={{textDecoration: 'none'}} href={e.href}>
          <div style={{display: 'inherit', alignItems: 'center'}}>
            <div className="link-item-title">{e.title}</div>
          </div>
          <div className="arrow-icon"> 
          <FontAwesomeIcon size="2xl" icon="fa-solid fa-caret-right" />
          </div>
        </a>
      })}
    </div>
  </>;
}

export default Links;
