import IconButton from "@mui/material/IconButton";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ClearIcon from "@mui/icons-material/Clear";
import { menuNames } from "../../../constants";

export default function NestedMenuTitle({ name, setCurrMenu }) {
  return (
    <>
      <div style={{ display: "inherit" }}>
        <div className="navbar-icon">
          <div onClick={() => setCurrMenu("Menu")}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
            >
              <ArrowLeftIcon fontSize="large" />
            </IconButton>
          </div>
        </div>
        <div className="navbar-title">{name}</div>
      </div>

      <div className="navbar-icon">
        <div
          style={{ color: "inherit" }}
          onClick={() => {
            setCurrMenu(menuNames.MAIN);
          }}
        >
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
          >
            <ClearIcon fontSize="large" />
          </IconButton>
        </div>
      </div>
    </>
  );
}
