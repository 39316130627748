import "./ReferenceItem.css";
import { useState } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import RefPaginator from "../RefPaginator/RefPaginator";
import { IconButton, Typography } from "@mui/material";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import useOrientation from "../../utils/useOrientation";

const ReferenceItem = ({ refItem, setShowItem }) => {
  const [currPage, setCurrPage] = useState(1);
  const orientation = useOrientation();
  const isLandscape = orientation === "landscape";

  const goBack = () => {
    if (currPage - 1 > 0) {
      setCurrPage(currPage - 1);
    }
  };

  const goNext = () => {
    if (currPage + 1 < refItem.length) {
      setCurrPage(currPage + 1);
    }
  };

  return (
    <div
      className="RefItem"
      style={{
        height: isLandscape ? "66vh" : "89vh",
        maxHeight: isLandscape ? "750px" : "none",
        backgroundColor: isLandscape ? "var(--bg)" : "white",
      }}
    >
      {/* {isLandscape && ( */}
      <IconButton
        onClick={() => setShowItem(false)}
        size="large"
        edge="start"
        color="inherit"
        aria-label="menu"
        sx={{
          textAlign: "left",
          margin: 0,
          padding: 0,
          alignSelf: "flex-start",
        }}
      >
        <ArrowLeftIcon fontSize="large" />
        <Typography variant="subtitle1" color="white">
          Back
        </Typography>
      </IconButton>
      {/* )} */}
      <Card id="ref-item-card" variant="outlined">
        <CardHeader id="ref-item-card-header" title={refItem[0].body} />
        <hr className="line-break" />
        {refItem[currPage].body}
      </Card>
      <RefPaginator
        goBack={goBack}
        goNext={goNext}
        currPage={currPage}
        totalPages={refItem.length - 1}
      />
    </div>
  );
};

export default ReferenceItem;
