import "./RefPaginator.css";
import { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import React from "react";
import { useLocation, useNavigate } from "react-router";

const RefPaginator = ({ totalPages, currPage, goBack, goNext }) => {
  let navigate = useNavigate();
  let location = useLocation();

  return (
    <>
      <div className="PageBar">
        <div onClick={() => goBack(currPage)}>
          <div className="pagebar-icon">
            <div>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ padding: 0 }}
              >
                <ArrowLeftIcon fontSize="large" />
              </IconButton>
            </div>
          </div>
        </div>
        <div className="pagebar-title">
          {currPage} / {totalPages}
        </div>
        <div className="pagebar-icon">
          <div onClick={() => goNext(currPage)}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ padding: 0 }}
            >
              <ArrowRightIcon fontSize="large" />
            </IconButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default RefPaginator;
