import { useNavigate } from "react-router";
import useOrientation from "../../../utils/useOrientation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MenuIcon, BookStackIcon, DuoLink } from "../../svg";
import { menuNames } from "../../../constants";
import DropDownMenu from "./DropDownMenu";
import References from "../../References/References";
import { useState } from "react";
import Links from "../../Links/Links";

export default function MainNav({ setCurrMenu }) {
  const [showReferences, setShowReferences] = useState(false);
  const [showLinks, setShowLinks] = useState(false);
  let nav = useNavigate();
  const orientation = useOrientation();

  return (
    <>
      <div
        onClick={() => nav("/")}
        style={{ display: "inherit", cursor: "pointer" }}
      >
        <div className="navbar-logo-icon">
          <div style={{ color: "inherit" }}>
            <FontAwesomeIcon icon="fa-solid fa-calculator" size="lg" />
          </div>
        </div>
        <div className="navbar-title">Simple Slope Calculator</div>
      </div>
      <div className="navbar-menu-icon">
        {orientation === "portrait" && (
          <div
            style={{ marginRight: "10px" }}
            onClick={() => {
              setCurrMenu(menuNames.MENU);
            }}
          >
            <MenuIcon />
          </div>
        )}
        {orientation === "landscape" && (
          <div style={{ display: "flex" }}>
            <div style={{ position: "relative" }}>
              <div
                style={{
                  marginRight: "20px",
                  display: "flex",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setShowLinks(false);
                  setShowReferences(!showReferences);
                }}
              >
                <BookStackIcon
                  style={{ fontSize: "23px", alignSelf: "center" }}
                />
                <div className="navbar-link-text">References</div>
              </div>
              {showReferences && (
                <DropDownMenu>
                  <References />
                </DropDownMenu>
              )}
            </div>

            <div style={{ position: "relative" }}>
              <div
                style={{
                  marginRight: "20px",
                  display: "flex",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setShowReferences(false);
                  setShowLinks(!showLinks);
                }}
              >
                <DuoLink style={{ fontSize: "23px", alignSelf: "center" }} />
                <div className="navbar-link-text">Links</div>
              </div>
              {showLinks && (
                <DropDownMenu>
                  <Links />
                </DropDownMenu>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
