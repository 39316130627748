const soilTypes = [
  {
    name: "Type A",
    minimum: 4,
    max: 20,
    slope: 53,
    ratioA: "3/4",
    ratioB: "1",
    angle: 0.75,
  },
  {
    name: "Type B",
    minimum: 4,
    max: 20,
    slope: 45,
    ratioA: "1",
    ratioB: "1",
    angle: 1,
  },
  {
    name: "Type C",
    minimum: 4,
    max: 20,
    slope: 34,
    ratioA: "1 1/2",
    ratioB: "1",
    angle: 1.5,
  },
];

export default soilTypes;
