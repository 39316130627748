import "./Menu.css";
import { BookStackIcon, RightCaret, DuoLink } from "../svg";
import References from "../References/References";
import Links from "../Links/Links";
import { CSSTransition } from "react-transition-group";
import { menuNames } from "../../constants";
import { useEffect, useState } from "react";

function Menu({ currMenu, setCurrMenu }) {
  const [isMounted, setIsmounted] = useState(false);
  useEffect(() => {
    setIsmounted(true);
  }, []);

  return (
    <div className="Menu">
      <CSSTransition
        in={currMenu === menuNames.REFERENCES}
        timeout={500}
        classNames="menu-secondary"
        unmountOnExit
      >
        <References />
      </CSSTransition>

      <CSSTransition
        in={currMenu === menuNames.LINKS}
        timeout={500}
        classNames="menu-secondary"
        unmountOnExit
      >
        <Links />
      </CSSTransition>

      <CSSTransition
        in={currMenu === menuNames.MENU && isMounted}
        timeout={500}
        classNames="menu-primary"
        unmountOnExit
      >
        <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
          <div
            className="menu-item"
            onClick={() => setCurrMenu(menuNames.REFERENCES)}
          >
            <div style={{ display: "inherit", alignItems: "center" }}>
              <div className="menu-item-icon">
                <BookStackIcon />
              </div>
              <div className="menu-item-title">References</div>
            </div>
            <div className="arrow-icon">
              <RightCaret />
            </div>
          </div>
          <div
            className="menu-item"
            onClick={() => setCurrMenu(menuNames.LINKS)}
          >
            <div style={{ display: "inherit", alignItems: "center" }}>
              <div className="menu-item-icon">
                <DuoLink />
              </div>
              <div className="menu-item-title">Links</div>
            </div>
            <div className="arrow-icon">
              <RightCaret />
            </div>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
}

export default Menu;
