import SvgIcon from "@mui/material/SvgIcon";
import { ReactComponent as Menu_Icon } from "./icons/menuIcon.svg";
import { ReactComponent as Right_Caret } from "./icons/caret-right-solid.svg";
import { ReactComponent as Right_Caret_Black } from "./icons/caret-right-solid-black.svg";
import { ReactComponent as Duo_Link } from "./icons/link-duotone.svg";
import { ReactComponent as BookStack_Icon } from "./icons/books-duotone.svg";
import { ReactComponent as leftCaretIcon } from "./icons/square-caret-left-duotone.svg";

export const MenuIcon = (props) => {
  return <SvgIcon {...props} inheritViewBox component={Menu_Icon}></SvgIcon>;
};

export const RightCaret = (props) => {
  return (
    <SvgIcon
      {...props}
      fontSize="large"
      inheritViewBox
      component={Right_Caret}
    ></SvgIcon>
  );
};

export const RightCaretBlack = (props) => {
  return (
    <SvgIcon {...props} inheritViewBox component={Right_Caret_Black}></SvgIcon>
  );
};

export const DuoLink = (props) => {
  return (
    <SvgIcon
      {...props}
      fontSize="large"
      inheritViewBox
      component={Duo_Link}
    ></SvgIcon>
  );
};

export const BookStackIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      fontSize="large"
      inheritViewBox
      component={BookStack_Icon}
    ></SvgIcon>
  );
};

export const SoilLeftArrow = (props) => {
  return (
    <SvgIcon {...props} inheritViewBox component={leftCaretIcon}></SvgIcon>
  );
};

export const SoilRightArrow = (props) => {
  return (
    <SvgIcon
      {...props}
      style={{ display: "block", transform: "scale(-1,1)" }}
      inheritViewBox
      component={leftCaretIcon}
    ></SvgIcon>
  );
};
