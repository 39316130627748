import { useRef, useEffect, useMemo } from "react";
import { Box } from "@mui/material";
import useOrientation from "../../../utils/useOrientation";
import usePageSize from "../../../utils/usePageSize";

const getPixelRatio = (ctx) => {
  var backingStore =
    ctx.backingStorePixelRatio ||
    ctx.webkitBackingStorePixelRatio ||
    ctx.mozBackingStorePixelRatio ||
    ctx.msBackingStorePixelRatio ||
    ctx.oBackingStorePixelRatio ||
    ctx.backingStorePixelRatio ||
    1;

  return (window.devicePixelRatio || 1) / backingStore;
};

const setCanvasScale = (ctx, canvas) => {
  let ratio = getPixelRatio(ctx);
  let canvasWidth = getComputedStyle(canvas)
    .getPropertyValue("width")
    .slice(0, -2);
  let canvasHeight = getComputedStyle(canvas)
    .getPropertyValue("height")
    .slice(0, -2);

  console.log({
    canvasWidth,
    canvasHeight,
    ratio,
    cWidth: (canvas.width = canvasWidth * ratio),
    cHeight: (canvas.height = canvasHeight * ratio),
  });
  canvas.width = canvasWidth * ratio;
  canvas.height = canvasHeight * ratio;
  canvas.style.width = `${canvasWidth}px`;
  canvas.style.height = `${canvasHeight}px`;
};

const render = (ctx, canvas, soil, depth, width, soilType, shield) => {
  let angle = depth <= soilType.minimum ? 0 : soilType.angle;
  if (shield) angle = 0;
  let lnWidth = Math.round(canvas.height * 0.015);
  let halfLnWd = lnWidth / 2;
  let heightScalingFactor = 30;
  let widthScalingFactor = 30;
  let wd = canvas.width;
  let ht = canvas.height;
  let trenchHeight = Math.round((ht / heightScalingFactor) * depth);
  let trenchWidth = Math.round((wd / widthScalingFactor) * width);
  let trenchStart = wd / 2 - trenchWidth / 2 - angle * trenchHeight;
  let trenchBottomStart = wd / 2 - trenchWidth / 2;
  let trenchEnd = wd / 2 + trenchWidth / 2 + angle * trenchHeight;
  let trenchBottomEnd = wd / 2 + trenchWidth / 2;
  let imageSide = wd > ht ? wd : ht;

  ctx.drawImage(soil, 0, 0, imageSide, imageSide);

  //Trench Fill
  ctx.fillStyle = "#FFF";
  ctx.beginPath();
  ctx.moveTo(0, 0);
  ctx.lineTo(trenchStart, 0);
  ctx.lineTo(trenchBottomStart, trenchHeight);
  ctx.lineTo(trenchBottomEnd, trenchHeight);
  ctx.lineTo(trenchEnd, 0);
  ctx.lineTo(wd, 0);
  ctx.fill();

  //Trench border
  ctx.strokeStyle = "#EBB54C";
  ctx.lineWidth = lnWidth;
  ctx.beginPath();
  ctx.moveTo(0, halfLnWd);
  ctx.lineTo(trenchStart, halfLnWd);
  ctx.lineTo(trenchBottomStart, trenchHeight);
  ctx.lineTo(trenchBottomEnd, trenchHeight);
  ctx.lineTo(trenchEnd, halfLnWd);
  ctx.lineTo(wd, halfLnWd);
  ctx.stroke();

  //Triangle
  let aW = Math.round(canvas.width * 0.08);
  let aH = Math.round(canvas.height / 1.8);
  let a = aH - Math.round(canvas.height * 0.25);
  let bW = aW;
  let bH = Math.round(aH + a);
  let b = Math.round(a * soilType.angle);
  let cH = bH;
  let cW = aW + b;
  ctx.fillStyle = "#EBB54C";
  ctx.beginPath();
  ctx.moveTo(aW, aH);
  ctx.lineTo(bW, bH);
  ctx.lineTo(cW, cH);
  ctx.lineTo(aW, aH);
  ctx.closePath();
  ctx.fill();

  ctx.strokeStyle = "#FFF";
  ctx.stroke();

  ctx.fillStyle = "#FFF";
  let x = canvas.height - cH;
  let fs = Math.round(x * 0.45);
  ctx.font = `bold ${fs}px sans-serif`;
  let y = a + fs;
  ctx.fillText(soilType.ratioB, aW - fs, aH + y / 2);
  ctx.fillText(soilType.ratioA, cW / 2 + 8, cH + fs + 5);

  if (shield && depth > 4) {
    let shieldThickness = lnWidth + width * 0.3;

    //Shield Walls
    ctx.fillStyle = "#244E9F";
    ctx.fillRect(
      trenchBottomStart + halfLnWd,
      0,
      shieldThickness,
      trenchHeight - halfLnWd
    );
    ctx.fillRect(
      trenchBottomEnd - shieldThickness - halfLnWd,
      0,
      shieldThickness,
      trenchHeight - halfLnWd
    );

    // Shield Bars
    ctx.fillStyle = "#00000029";
    ctx.fillRect(
      trenchBottomStart + halfLnWd + shieldThickness,
      shieldThickness,
      trenchWidth - shieldThickness * 2 - lnWidth,
      shieldThickness / 3
    );
    ctx.fillRect(
      trenchBottomStart + halfLnWd + shieldThickness,
      shieldThickness * 2.5,
      trenchWidth - shieldThickness * 2 - lnWidth,
      shieldThickness / 3
    );
    ctx.fillStyle = "#BEBEBE";
    ctx.fillRect(
      trenchBottomStart + halfLnWd + shieldThickness,
      shieldThickness + shieldThickness / 3,
      trenchWidth - shieldThickness * 2 - lnWidth,
      shieldThickness / 2
    );
    ctx.fillRect(
      trenchBottomStart + halfLnWd + shieldThickness,
      shieldThickness * 2.5 + shieldThickness / 3,
      trenchWidth - shieldThickness * 2 - lnWidth,
      shieldThickness / 2
    );
  }
};

function SlopeCanvas({ depth, width, soilType, shield }) {
  let ref = useRef();
  const pageSize = usePageSize();
  const orientation = useOrientation();

  let soil = useMemo(() => {
    console.log("RUNNING");
    let canvas = ref.current;
    if (canvas) {
      let ctx = canvas.getContext("2d");
      setCanvasScale(ctx, canvas);

      let soil = new Image();
      soil.src = "./soil.jpeg";
      soil.onload = () => {
        render(ctx, canvas, soil, depth, width, soilType, shield);
      };
      return soil;
    }
    return null;
    // eslint-disable-next-line
  }, [ref.current]);

  useEffect(() => {
    let canvas = ref.current;
    if (canvas) {
      // Need to reset height and width otherwise canvas wont resize
      canvas.style.width = `100%`;
      canvas.style.height = `100%`;
    }
  }, [pageSize.width, pageSize.height]);

  useEffect(() => {
    let canvas = ref.current;
    let ctx = canvas.getContext("2d");
    if (canvas && ctx && soil) {
      render(ctx, canvas, soil, depth, width, soilType, shield);
    }
  }, [depth, width, soil, soilType, shield]);

  return (
    <Box
      className="slope-canvas"
      sx={{
        height: "calc(100% - 50px)",
        marginBottom: "-6px",
      }}
    >
      <canvas
        ref={ref}
        style={{
          width: "100%",
          height: "100%",
          borderRadius: orientation === "landscape" ? "0 0 10px 0" : "0px",
        }}
      />
    </Box>
  );
}

export default SlopeCanvas;
