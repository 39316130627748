import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { menuNames } from "../../constants";
import "./NavBar.css";
import useOrientation from "../../utils/useOrientation";
import MainNav from "./components/MainNav";
import NestedMenuTitle from "./components/NestedMenuTitle";

function NavBar({ currMenu, setCurrMenu }) {
  const orientation = useOrientation();

  if (orientation === "landscape")
    return (
      <div className="NavBar">
        <div className="navbar-container">
          <MainNav setCurrMenu={setCurrMenu} />
        </div>
      </div>
    );

  return (
    <div className="NavBar">
      <div className="navbar-container">
        {currMenu === menuNames.MAIN && <MainNav setCurrMenu={setCurrMenu} />}
        {currMenu === menuNames.MENU && (
          <>
            <div className="navbar-title">Menu</div>
            <div className="navbar-icon">
              <div
                style={{ color: "inherit" }}
                onClick={() => {
                  setCurrMenu(menuNames.MAIN);
                }}
              >
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                >
                  <ClearIcon fontSize="large" />
                </IconButton>
              </div>
            </div>
          </>
        )}
        {currMenu === menuNames.REFERENCES && (
          <NestedMenuTitle
            name={menuNames.REFERENCES}
            setCurrMenu={setCurrMenu}
          />
        )}
        {currMenu === menuNames.LINKS && (
          <NestedMenuTitle name={menuNames.LINKS} setCurrMenu={setCurrMenu} />
        )}
      </div>
    </div>
  );
}

export default NavBar;
